export interface Country {
  countryCode: string,
  name: string
}

export class LocalizedCountry {
  constructor(
    public countryCode: string,
    public name: string
  ) {
  }
}

export class Region {
  constructor(
    public regionCode: string,
    public name: string
  ) {
  }
}
