import { Page } from '@mybitzer-workspace/shared'
import { CategorySelectRequest, RelatedCategoryIdEntry } from "../document"

export interface PortalGeneralSearchResponse {
  documents: Page<PortalDocumentSearchResultEntry>
  products: Page<PortalProductSearchResultEntry>
  series: Page<PortalSeriesSearchResultEntry>
  spareParts: Page<PortalSparePartSearchResultEntry>
}

export class PortalGeneralSearchRequest {
  constructor(
    public fullTextSearch: string,
    public lang: string
  ) {
  }

}

export class PortalDocumentSearchRequest implements CategorySelectRequest {
  selectedApplicationId = ''
  selectedTechnologyId = ''

  constructor(
    public categoryIds: string[],
    public documentTypes: string[],
    public fullTextSearch: string,
    public lang: string,
    public country: string,
    public productIds: string[],
    public seriesIds: string[]
  ) {
  }
}

export interface PortalDocumentSearchResponse {
  entries: Page<PortalDocumentSearchResultEntry>
  types: PortalDocumentTypeResultEntry[]
  categories: RelatedCategoryIdEntry[]
}

export interface PortalDocumentTypeResultEntry {
  amount: number,
  type: string,
  typeName: string
}

export interface PortalDocumentSearchResultEntry {
  title?: string,
  lang: string[],
  code: string,
  version: string,
  url: string,
  typeName: string,
  type: string,
  fileType: string,
  hasVideo: boolean,
  technology: string,
}

export class PortalProductSearchRequest implements CategorySelectRequest {
  selectedApplicationId = ''
  selectedTechnologyId = ''

  constructor(
    public categoryIds: string[],
    public technologyIds: string[],
    public seriesIds: string[],
    public fullTextSearch: string,
    public lang: string
  ) {
  }
}

export interface PortalProductSearchResponse {
  entries: Page<PortalProductSearchResultEntry>,
  technology: PortalProductTechnologyResultEntry[],
  series: PortalProductSeriesResultEntry[],
  categories: RelatedCategoryIdEntry[]
}

export interface PortalProductSearchResultEntry {
  id: string,
  technology: string,
  technologyName: string,
  series: string,
  seriesName: string,
  legacy: boolean,
  internal: boolean,
  matNrKMat: string,
  hasDocuments: boolean,
  hasSpareParts: boolean,
  hasProductConfigIds: boolean
}

export interface PortalProductTechnologyResultEntry {
  technology: string,
  amount: number
}

export interface PortalProductSeriesResultEntry {
  series: string,
  seriesName: string,
  amount: number
}

export class PortalSeriesSearchRequest implements CategorySelectRequest {
  selectedApplicationId = ''
  selectedTechnologyId = ''

  constructor(
    public categoryIds: string[],
    public fullTextSearch: string,
    public lang: string
  ) {
  }
}

export interface PortalSeriesSearchResponse {
  entries: Page<PortalSeriesSearchResultEntry>,
  categories: RelatedCategoryIdEntry[]
}

export interface PortalSeriesSearchResultEntry {
  id: string,
  title: string,
  description: string,

  picture: string,
  mediaTitle: string,
  pictogram: string[],

  documents: string[]
}

export class PortalSparePartSearchRequest {
  constructor(
    public fullTextSearch: string,
    public lang: string
  ) {
  }
}

export interface PortalSparePartSearchResponse {
  entries: Page<PortalSparePartSearchResultEntry>
}

export interface PortalSparePartSearchResultEntry {
  id: string,
  titleSales: string,
  titleShort: string,
  crossplantStatus: string,
  weight: string,
  dimensions: string,
  picture: string,
  internal: boolean,
  description: string
}
