export class PowerSearchRequest {
  constructor(
    public from: string,
    public to: string,
  ) {
  }
}

export interface PowerReport {
  total: PowerEntry[],
  delta: PowerEntry[],
}

export interface PowerEntry {
  value: number,
  timestamp: string,
}

export interface ItemPowerEntry {
  itemId: number,
  total: number,
  uom: string
}
