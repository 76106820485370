import { ModbusSettings } from "@mybitzer-workspace/shared"

export interface BestInfo {
  "online": boolean,
  "firmwareVersion": string,
  "macAddress": string,
  "device": {
    "protocol": string,
    "enabled": boolean,
    "online": boolean,
    "address": string,
    "type": string,
    "serialNumber": string,
    "commRetransmissions": number,
    "commTransmissionss": number,
    "commErrors": number
  }
}
export class DeviceUpdateRequest {
  constructor(
    public thingId: number,
    public protocol: string,
    public macAddress: string,
    public deviceAddress: number,
    public moduleId: number,
    public modbus: ModbusSettings | null
  ) { }
}

export class DeviceUpdateResponse {
  constructor(
    public subscriptionResponse: BestSubscriptionResponse | null,
    public bestInfo: BestInfo,
  ) { }
}

export class BestSubscriptionResponse {
  constructor(
    public Code: number,
    public Description: BestSubscriptionDescription
  ) { }
}

export class BestSubscriptionDescription {
  constructor(
    public Key: string | null,
    public Text: string | null
  ) { }
}
