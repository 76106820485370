import { HttpParams } from "@angular/common/http"
import { Injectable } from '@angular/core'
import { BaseService } from "@mybitzer-workspace/shared"
import { Observable } from "rxjs"
import { Translation } from "./translation.api"

@Injectable({
  providedIn: 'root'
})
export class TranslationService extends BaseService {

  constructor() {
    super('translation')
    this.retryCount = 1
  }

  getUOMTranslations(lang: string): Observable<Translation> {
    const params = new HttpParams().append('lang', lang)
    return this.get('type/uom', params)
  }

  getProfileTranslations(lang: string): Observable<Translation> {
    const params = new HttpParams().append('lang', lang)
    return this.get('profile', params)
  }

  getLandingTranslations(lang: string): Observable<Translation> {
    const params = new HttpParams().append('lang', lang)
    return this.get('landing', params)
  }
}
