import { HttpParams } from "@angular/common/http"
import { Injectable } from '@angular/core'
import { BaseService, Page } from "@mybitzer-workspace/shared"
import { Observable } from "rxjs"
import { ExportDeviceEntry } from "./export-api"

@Injectable({
  providedIn: 'root'
})
export class DataExportService extends BaseService {

  constructor() {
    super('export')
  }

  getDevices(page: number, size = 20): Observable<Page<ExportDeviceEntry>> {
    return this.getPaged<ExportDeviceEntry>('device', page, size)
  }

  getAllSystems(page: number, size = 20): Observable<Page<any>> {
    return this.getPaged('system', page, size)
  }

  getFilteredSystems(
    page: number,
    size = 20,
    systemFilter = '',
    refrigerantFilter = '',
    compressorFilter = ''
  ): Observable<Page<any>> {
    let params = new HttpParams()
    params = params.append('systemFilter', systemFilter)
    params = params.append('refrigerantFilter', refrigerantFilter)
    params = params.append('compressorFilter', compressorFilter)
    return this.getPaged('system/filter', page, size, params)
  }
}
