import { Page } from '@mybitzer-workspace/shared'
export interface KnowledgeEquipment {
  id: string,
  materialNumber: string,

  attributes: KnowledgeEquipmentAttributes,
  references: KnowledgeEquipmentReferences
}

export interface KnowledgeEquipmentAttributes {
  general: KnowledgeEquipmentGeneralAttributes | undefined
  customerOrder: CustomerOrderAttributes | undefined
  manufacturing: ManufacturingAttributes | undefined
}

export interface KnowledgeEquipmentGeneralAttributes {
  type: string,
  composition: string,
  motor: string,
}

export interface CustomerOrderAttributes {
  orderNumber: string,
  orderItem: string,
}

export interface ManufacturingAttributes {
  plant: string,
  orderNumber: string,
  timestamp: string
}

export interface KnowledgeEquipmentReferences {
  configuration: ConfigurationReferences | undefined
}

export interface ConfigurationReferences {
  type: string,
  configurationUnit: string,
  // cstics: Map<String, String>,
  keyCstics: KeyCstics[]
}

export interface KeyCstics {
  id: string,
  defaultName: string,
  ue: string,
}

export class EquipmentSearchRequest {
  constructor(
    public fullTextSearch: string,
    public lang: string
  ) {
  }
}

export interface KnowledgeEquipmentSearchResponse {
  entries: Page<KnowledgeEquipmentSearchResultEntry>
}

export interface KnowledgeEquipmentSearchResultEntry {
  id: string,
  materialNumber: string,
  type: string,
  composition: string,
  motor: string,
}
