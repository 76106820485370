import { HttpParams } from "@angular/common/http"
import { Injectable, signal } from '@angular/core'
import { BaseService, Page } from "@mybitzer-workspace/shared"
import { TranslateService } from '@ngx-translate/core'
import { Observable, tap } from "rxjs"
import { Feedback, FeedbackEntry, FeedbackQuestionSetInfo, FeedbackRequest } from "./feedback-api"

@Injectable({
  providedIn: 'root'
})
export class FeedbackService extends BaseService {
  constructor(
    private readonly translate: TranslateService
  ) {
    super('feedback')
    this.retryCount = 0
  }

  feedback(request: FeedbackRequest): Observable<any> {
    request.language = this.translate.currentLang

    return this.post('', request).pipe(tap(() => localStorage.setItem(request.url, '')))
  }

  showFeedback(url: string) {
    return localStorage.getItem(url) == null
  }

  createPoints(size: number): number[] {
    return Array(size).fill(1).map((x, i) => i + 1)
  }

  getPosition(): Observable<any> {
    return new Observable((subscriber) => {
      navigator.geolocation.getCurrentPosition(
        p => {
          subscriber.next({ lon: p.coords.longitude, lat: p.coords.latitude })
          subscriber.complete()
        },
        e => {
          subscriber.next({ lon: 0, lat: 0 })
          subscriber.complete()
        })
    })
  }

  getAllFeedback(page: number, size: number): Observable<Page<Feedback>> {
    return this.getPaged('', page, size)
  }

  getEntry(id: number): Observable<FeedbackEntry> {
    return this.get(id + '')
  }

  getLandingpageFeedback(): Observable<FeedbackQuestionSetInfo> {
    const params = new HttpParams().append('lang', this.translate.currentLang)
    return this.get('questions/landingpage', params)
  }

}
