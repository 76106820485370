import { Injectable } from '@angular/core'
import { AuthService, BaseService, Process, ProcessResponse, REGISTRATION_CREATE } from '@mybitzer-workspace/shared'
import { Observable } from "rxjs"
import { RegisterEquipmentRequest, RegisterSiteRequest, RegisterSystemRequest, Registration, } from "./registration-api"

@Injectable({
  providedIn: 'root'
})
export class RegistrationService extends BaseService {

  constructor(
    private readonly authService: AuthService
  ) {
    super('registration')
  }

  siteCreate(request: RegisterSiteRequest): Observable<Registration> {
    return this.post('site', request)
  }

  siteUpdate(siteId: string, request: RegisterSiteRequest): Observable<Registration> {
    return this.put("site/" + siteId, request)
  }

  siteRemove(siteId: string): Observable<Registration> {
    return this.delete("site/" + siteId)
  }

  systemCreate(request: RegisterSystemRequest, siteId: string): Observable<Registration> {
    return this.post("system/" + siteId, request)
  }

  systemUpdate(systemId: string, request: RegisterSystemRequest): Observable<Registration> {
    return this.put("system/" + systemId, request)
  }

  systemRemove(systemId: string): Observable<Registration> {
    return this.delete("system/" + systemId)
  }

  equipmentCreate(request: RegisterEquipmentRequest, systemId: string): Observable<Registration> {
    return this.post("equipment/" + systemId, request)
  }

  equipmentUpdate(equipmentId: string, request: RegisterEquipmentRequest): Observable<Registration> {
    return this.put("equipment/" + equipmentId, request)
  }

  equipmentRemove(equipmentId: string): Observable<Registration> {
    return this.delete("equipment/" + equipmentId)
  }

  getRegistration(): Observable<Registration> {
    return this.get("")
  }

  clear(): Observable<Registration> {
    return this.delete("")
  }

  submit(): Observable<ProcessResponse> {
    return this.post("", {})
  }

  getProcess(): Observable<Process> {
    return this.get("process")
  }

  selectSite(siteId: number): Observable<Registration> {
    return this.post("site/" + siteId + "/select", {})
  }

  selectSystem(systemId: number): Observable<Registration> {
    return this.post("system/" + systemId + "/select", {})
  }

  hasRegistrationPermissions(): boolean {
    return this.authService.hasRole(REGISTRATION_CREATE)
  }
}
