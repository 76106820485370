import { Injectable } from "@angular/core"
import { BaseService, Page } from "@mybitzer-workspace/shared"
import { Observable } from "rxjs"
import { LogbookChangeRequest, LogbookEntry, LogbookEntryCategory, LogbookEntryTag } from "./logbook-api"

@Injectable({
  providedIn: 'root'
})
export class LogbookService extends BaseService {

  constructor() {
    super('logbook')
    this.retryCount = 1
  }

  createItemEntry(itemId: number, request: LogbookChangeRequest): Observable<LogbookEntry> {
    return this.post(`item/${itemId}`, request)
  }

  createEquipmentEntry(equipmentId: number, request: LogbookChangeRequest) {
    return this.post(`equipment/${equipmentId}`, request)
  }

  getByItemId(itemId: number, page: number, size: number): Observable<Page<LogbookEntry>> {
    return this.getPaged(`item/${itemId}`, page, size)
  }

  getByEquipmentId(equipmentId: number, page: number, size: number): Observable<Page<LogbookEntry>> {
    return this.getPaged(`equipment/${equipmentId}`, page, size)
  }

  getCategories(): Observable<LogbookEntryCategory[]> {
    return this.get(`category`)
  }

  getTags(): Observable<LogbookEntryTag[]> {
    return this.get(`tags`)
  }
}
