import { HttpParams } from "@angular/common/http"
import { Injectable } from '@angular/core'
import { BaseService } from '@mybitzer-workspace/shared'
import { TranslateService } from "@ngx-translate/core"
import { Observable } from "rxjs"
import {
  CartEntryChangeRequest,
  CartEntryInfoChangeResponse,
  CartInfo,
  CartSummary,
  OrderResponse,
  PriceRequest,
  PriceResponse,
  ShareCartRequest,
  SharedCart,
  Store
} from "./commerce-api"

@Injectable({
  providedIn: 'root'
})
export class CommerceService extends BaseService {

  constructor(
    private readonly translateService: TranslateService
  ) {
    super('commerce')
    this.retryCount = 0
  }

  addToCart(request: CartEntryChangeRequest): Observable<CartEntryInfoChangeResponse> {
    return this.post('cart/entry', request)
  }

  getCart(): Observable<CartInfo> {
    let params = new HttpParams()
    params = params.append('lang', this.translateService.currentLang)
    return this.get('cart', params)
  }

  updateEntry(entryId: number, request: CartEntryChangeRequest): Observable<CartEntryInfoChangeResponse> {
    return this.put(`cart/entry/${entryId}`, request)
  }

  deleteEntry(entryId: number): Observable<CartSummary> {
    return this.delete(`cart/entry/${entryId}`)
  }

  deleteAllEntries(): Observable<CartSummary> {
    return this.delete(`cart/entry`)
  }

  getPrices(request: PriceRequest): Observable<PriceResponse> {
    return this.post('price', request)
  }

  getStores(): Observable<Store[]> {
    return this.get('store')
  }

  placeOrder(storeId: number): Observable<OrderResponse> {
    return this.post(`store/${storeId}/order`, {})
  }

  exportCart(): Observable<any> {
    return this.download('cart/export')
  }

  getSharedCart(id: string): Observable<SharedCart> {
    let params = new HttpParams()
    params = params.append('lang', this.translateService.currentLang)
    return this.get('cart/share/' + id, params)
  }


  shareCart(request: ShareCartRequest): Observable<string> {
    return this.postText('cart/share', request)
  }

  importSharedCartIntoCart(sharedCartId: string) {
    return this.post(`cart/share/${sharedCartId}/import`, {})
  }

  exportSharedCart(sharedCartId: string) {
    return this.download(`cart/share/${sharedCartId}/export`)
  }
}
