import { EquipmentInfo } from "@mybitzer-workspace/shared"
import { Interval, Period } from "./operational-report.api"

export class EquipmentLoadReport {
  constructor(
    public period: Period,
    public info: EquipmentInfo,
    public series: EquipmentLoadSeries[]
  ) { }
}

export class EquipmentLoadSeries {
  constructor(
    public property: PropertyDefinition,
    public entries: EquipmentLoadSeriesEntry[]
  ) { }
}

export class EquipmentLoadSeriesEntry {
  constructor(
    public interval: Interval,
    public percentage: number,
    public value: number
  ) { }
}

export class EquipmentLoadReportRequest {
  constructor(
    public from: string,
    public to: string,
    public propertyNames: string[]
  ) { }
}
