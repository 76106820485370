import { Injectable } from "@angular/core"

import { BaseService } from "@mybitzer-workspace/shared"
import { map, Observable } from "rxjs"
import { PortalSearchService } from "../portal"
import { ProductSearchRequest } from "../search"


@Injectable({
  providedIn: 'root'
})
/** @deprecated get rid of that **/
export class KnowledgeService extends BaseService {

  constructor(
    private readonly searchService: PortalSearchService
  ) {
    super('knowledge')
  }

  getTypes(input: string): Observable<string[]> {
    return this.getTypeData(input)
  }


  getSystemType() {
    return ['LT', 'MT', 'AC', 'HP']
  }

  getElectronicModules(): string[] {
    return [
      '',
      'CM-RC-01',
      'CM-SW-01',
      'CM-RC-02',
      'SE-i1',
      'LFC',
      'VARIPACK',
      'CSV',
      'ECOSTAR-LHV5E7E',
      'VARIPACK2',
      'ECOLITE.A2L',
      'BIM-1000'
    ]
  }

  private getTypeData(input: string): Observable<string[]> {
    const request = new ProductSearchRequest([], [], [], input, 'en')
    return this.searchService.searchProducts(request, 0, 10).pipe(
      map(value => value.body?.entries?.content.map(c => c.id) ?? [])
    )
  }

}
