import { Injectable } from '@angular/core'
import { BaseService } from '@mybitzer-workspace/shared'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class RefrigerantService extends BaseService {
  private refrigerants: string[] = []
  subject: Subject<string[]> = new Subject()
  constructor() {
    super('refrigerant')
  }

  getData() {
    if (!this.refrigerants || this.refrigerants.length == 0) this.getRefrigerantData()
  }

  loadRefrigerants() {
    if (this.refrigerants.length) {
      return this.refrigerants
    }

    this.getRefrigerantData()
    return []
  }

  private getRefrigerantData() {
    this.get('').subscribe(d => {
      if (d) {
        this.refrigerants = d as []
        this.subject.next(this.refrigerants)
      }
    })
  }

}
