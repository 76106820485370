import { HttpParams } from "@angular/common/http"
import { Injectable } from '@angular/core'
import { BaseService } from '@mybitzer-workspace/shared'
import { TranslateService } from "@ngx-translate/core"
import { Observable } from "rxjs"
import { SparePartCategory } from '../../product'
import { SparePartSearchRequest, SparePartSearchResponse } from "../../search"
import { CategorySparePart, SparePartInfo } from "./spare-part-api"

@Injectable({
  providedIn: 'root'
})
export class SparePartService extends BaseService {

  constructor(
    private readonly translateService: TranslateService
  ) {
    super('sparepart')
    this.retryCount = 1
  }

  getMediaContent(media: string): Observable<string> {
    return this.http.get('/api/sparepart/drawing/exploded/' + media, { responseType: 'text' })
  }

  getCategorySpareParts(categoryId: number): Observable<CategorySparePart[]> {
    let params = new HttpParams()
    params = params.append('lang', this.translateService.currentLang)
    return this.get('category/' + categoryId, params)
  }

  getInfo(id: string): Observable<SparePartInfo> {
    let params = new HttpParams()
    params = params.append('lang', this.translateService.currentLang)
    return this.get('info/' + id, params)
  }

  getSparePartDetails(id: string): Observable<SparePartInfo> {
    let params = new HttpParams()
    params = params.append('lang', this.translateService.currentLang)
    return this.get(`${id}`, params)
  }
  getCategoryByProductAndSparePart(id: string, productId: string): Observable<SparePartCategory> {
    let params = new HttpParams()
    params = params.append('lang', this.translateService.currentLang)
    return this.get('info/' + id + '/product/' + productId, params)
  }

  search(request: SparePartSearchRequest, page: number, size: number): Observable<SparePartSearchResponse> {
    const params = new HttpParams()
      .append('page', page)
      .append('size', size)
    return this.post<SparePartSearchResponse>('search', request, params)
  }
}
