import { Injectable } from '@angular/core'
import { PageEvent } from "@angular/material/paginator"
import { BaseService, Page } from '@mybitzer-workspace/shared'
import { BehaviorSubject, Observable } from "rxjs"
import { FilterService } from '../filter/filter.service'
import { ResourceSearchRequest, SearchResultEntry } from "./search-api"

@Injectable({
  providedIn: 'root'
})
export class SearchService extends BaseService {


  query = ""
  refrigerantFilter = ""
  systemTypeFilter = ""
  ownerFilter = ""

  availableTypes: string[] = ['SITE', 'SYSTEM', 'EQUIPMENT']
  typeFilter: string[] = []

  reloading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  pageIndex = 0
  pageSize = 20
  totalSize = -1

  result: SearchResultEntry[] = []
  private initialized = false

  constructor(
    private readonly filterService: FilterService
  ) {
    super('search')

    this.filterService.activeFilter.subscribe(_ => this.runSearch(this.pageIndex, this.pageSize))
    this.initialized = true
  }

  private search(request: ResourceSearchRequest, page: number, size: number): Observable<Page<SearchResultEntry>> {
    return this.postPaged('resource', request, page, size)
  }


  private runSearch(page: number, size: number) {
    if (!this.initialized) return
    if (this.reloading.value) return console.error("Ignore change due to ongoing request")
    this.reloading.next(true)
    const filter = this.filterService.activeFilter.value
    const request = new ResourceSearchRequest(
      this.query,
      filter.onlineFilter,
      filter.alarmFilter,
      filter.lastAlarms,
      this.refrigerantFilter,
      this.systemTypeFilter,
      this.ownerFilter,
      this.typeFilter
    )
    this.search(request, page, size).subscribe(d => this.handleData(d))
  }

  private handleData(d: Page<SearchResultEntry>) {
    this.result = d.content
    this.pageIndex = d.pageable.number
    this.pageSize = d.pageable.size
    this.totalSize = d.totalSize
    this.reloading.next(false)
  }

  handlePaginatorEvent(event: PageEvent) {
    this.runSearch(event.pageIndex, event.pageSize)
  }

  setFilter(query: string, refrigerant: string, systemType: string, owner: string, types: string[]) {
    this.query = query
    this.refrigerantFilter = refrigerant
    this.systemTypeFilter = systemType
    this.ownerFilter = owner
    this.typeFilter = types
    this.runSearch(this.pageIndex, this.pageSize)
  }
}
