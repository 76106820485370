import { HttpResponse } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { BaseService } from "@mybitzer-workspace/shared"
import { TranslateService } from "@ngx-translate/core"
import { Observable } from "rxjs"
import { DocumentSearchRequest, DocumentService } from "../document"
import { ProductService } from "../product"
import { DocumentSearchResponse, GeneralSearchRequest, GeneralSearchResponse, ProductSearchRequest, ProductSearchResponse, SeriesSearchRequest, SeriesSearchResponse, SparePartSearchRequest, SparePartSearchResponse } from "../search"
import { SeriesService } from "../series"
import {
  PortalGeneralSearchResponse
} from "./portal-search-api"
import { SparePartService } from "./spare-parts"

@Injectable({
  providedIn: 'root'
})
export class PortalSearchService extends BaseService {

  constructor(
    private readonly translateService: TranslateService,
    private readonly documentService: DocumentService,
    private readonly productService: ProductService,
    private readonly seriesService: SeriesService,
    private readonly sparePartService: SparePartService,
  ) {
    super('search')
    this.retryCount = 0
  }

  searchGeneral(query: string): Observable<PortalGeneralSearchResponse> {
    const request = new GeneralSearchRequest(query, this.translateService.currentLang)
    return this.post<GeneralSearchResponse>('general', request)
  }

  searchDocuments(request: DocumentSearchRequest, page: number, size: number): Observable<DocumentSearchResponse> {
    return this.documentService.search(request, page, size)
  }

  searchProducts(request: ProductSearchRequest, page: number, size: number): Observable<HttpResponse<ProductSearchResponse>> {
    return this.productService.search(request, page, size)
  }

  searchSeries(request: SeriesSearchRequest, page: number, size: number): Observable<HttpResponse<SeriesSearchResponse>> {
    return this.seriesService.search(request, page, size)
  }

  searchSpareParts(request: SparePartSearchRequest, page: number, size: number): Observable<SparePartSearchResponse> {
    return this.sparePartService.search(request, page, size)
  }


}
