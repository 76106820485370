import { Equipment, System } from "@mybitzer-workspace/shared"
import { ElectronicModule } from "../module"
import { Site } from "../site"
import { EquipmentActivityReport } from "./operational-report-activity.api"
import { EquipmentAlarmsReport } from "./operational-report-alarms.api"
import { EquipmentLoadReport } from "./operational-report-load.api"
import { EquipmentStartsReport } from "./operational-report-starts.api"

export class EquipmentOperationalReport {
  constructor(
    public period: Period,
    public info: EquipmentInfo,
    public status: EquipmentStatusReport,
    public activity: EquipmentActivityReport,
    public load: EquipmentLoadReport,
    public starts: EquipmentStartsReport,
    public alarms: EquipmentAlarmsReport
  ) { }
}

export class Period {
  constructor(
    public start: string,
    public end: string
  ) { }
}

export class EquipmentInfo {
  constructor(
    public equipment: Equipment,
    public system: System,
    public site: Site,
    public electronicModule: ElectronicModule
  ) { }
}

export class EquipmentOperationReportRequest {
  constructor(
    public from: string,
    public to: string,
    public lang: string,
    public propertyNames: string[],
    public meanPropertyNames: string[],
    public weatherSeriesName: string,
    public timezone: string
  ) { }
}

export class EquipmentStatusReport {
  constructor(
    public period: Period,
    public info: EquipmentInfo,
    public status: string
  ) { }
}

export class Interval {
  constructor(
    public start: number,
    public end: number
  ) { }
}
