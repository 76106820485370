import { HttpParams } from "@angular/common/http"
import { Injectable } from '@angular/core'
import { BaseService, Page } from '@mybitzer-workspace/shared'
import { Observable } from "rxjs"
import {
  FindResult,
  LongResult,
  SalesHub,
  SalesOrder,
  SalesOrderHistoryLog,
  SalesOrderSearchInfoResponse,
  SalesOrderSearchRequest,
  SalesRelationSearchRequest
} from "./sales-order-api"

@Injectable({
  providedIn: 'root'
})
export class SalesOrderService extends BaseService {

  constructor(

  ) {
    super('order')
    this.retryCount = 1
  }

  search(request: SalesOrderSearchRequest, page: number, size: number): Observable<SalesOrderSearchInfoResponse> {
    const params = new HttpParams()
      .append('page', page)
      .append('size', size)
    return this.post('search', request, params)
  }

  count(request: SalesOrderSearchRequest): Observable<LongResult> {
    return this.post('search/count', request)
  }

  getSalesHubs(): Observable<SalesHub[]> {
    return this.get('hub')
  }

  getHubSalesOffices(hubs: SalesHub[]): Observable<FindResult[]> {
    return this.post('hub/sales-office', hubs.map(hub => hub.id))
  }

  findB2BUnit(request: SalesRelationSearchRequest, page: number, size: number): Observable<Page<FindResult>> {
    const params = new HttpParams()
      .append('page', page)
      .append('size', size)
    return this.post('find/b2bunit', request, params)
  }

  findSalesOffice(query: string, page: number, size: number): Observable<Page<FindResult>> {
    let params = new HttpParams()
    params = params.append('query', query)
    return this.getPaged('find/sales-office', page, size, params)
  }

  getOrder(id: string, lang: string): Observable<SalesOrder> {
    let params = new HttpParams()
    params = params.append('lang', lang)
    return this.get(id, params)
  }


  getHistory(id: string, lang: string): Observable<SalesOrder> {
    let params = new HttpParams()
    params = params.append('lang', lang)
    return this.get('history/' + id, params)
  }

  getHistoryLog(id: string, lang: string, page: number, size: number): Observable<SalesOrderHistoryLog> {
    let params = new HttpParams()
    params = params.append('lang', lang)
      .append('page', page)
      .append('size', size)
    return this.get('history/' + id + '/log', params)
  }


  getHistoryHeader(id: string, lang: string, page: number): Observable<SalesOrder> {
    let params = new HttpParams()
    params = params.append('lang', lang)
    return this.get('history/' + id + '/header', params)
  }


  getHistoryItem(id: string, itemId: string, lang: string): Observable<SalesOrder> {
    let params = new HttpParams()
    params = params.append('lang', lang)
    return this.get('history/' + id + '/item/' + itemId, params)
  }
}
