import { Injectable } from "@angular/core"
import { BaseService, IMenuService, Menu } from "@mybitzer-workspace/shared"
import { Observable } from "rxjs"

@Injectable({
  providedIn: 'root'
})
export class MenuService extends BaseService implements IMenuService {

  constructor() {
    super('menu')
  }

  getMenu(): Observable<Menu> {
    return this.get('')
  }
}
