export enum ServiceType {
  DISTRIBUTOR = "DISTRIBUTOR",
  SUB_COMPANY = "SUB_COMPANY",
  RETAILER = "RETAILER",
  PRODUCTION = "PRODUCTION",
  GREENPOINT = "GREENPOINT",
  AUTHORIZED_RETAILER = "AUTHORIZED_RETAILER",
  AUTHORIZED_DISTRIBUTOR = "AUTHORIZED_DISTRIBUTOR",
  BITZER_LOCATION = "BITZER_LOCATION",
  AFTER_SALES_SERVICES = "AFTER_SALES_SERVICES"
}

export interface WorldwideLocation {
  lat: number,
  lon: number
}

interface GeoBox {
  bottomRight: WorldwideLocation,
  topLeft: WorldwideLocation
}

export class WorldWideServiceRequest {
  constructor(
    public continent: string,
    public country: string,
    public types: ServiceType[],
    public fullTextSearch: string,
    public geoBox: GeoBox | null,
    public lang: string
  ) {}
}

export interface WorldWideSearchResponse {
  entries: {
    content: WorldWideResultEntry[]
  }
}

export interface WorldWideResultEntry {
  assignedContinent: string,
  assignedCountry: string,
  assignedRegion: string,
  city: string,
  contact: string,
  emails: string[],
  fax: string,
  location: WorldwideLocation,
  phones: string[],
  poBox: string,
  street: string,
  subtitle: string,
  title: string,
  types: string[],
  url: string,
  website: string,
  zip: string
}
