import { HttpParams } from "@angular/common/http"
import { Injectable } from '@angular/core'
import { BaseService, Page } from "@mybitzer-workspace/shared"
import { Observable } from "rxjs"
import { ActiveAlarmInfoEntry, AlarmInfo, AlarmSearchRequest } from "./alarm-api"

@Injectable({
  providedIn: 'root'
})
export class AlarmService extends BaseService {

  constructor(

  ) {
    super('alarm')
  }

  search(equipmentId: number, start: string, end: string, lang: string, page: number, size: number): Observable<Page<AlarmInfo>> {
    const request: AlarmSearchRequest = {
      from: start,
      to: end
    }
    let params = new HttpParams()
    params = params.append('lang', lang)
    return this.postPaged('search/' + equipmentId + '/info', request, page, size, params)
  }


  getActiveAlarms(equipmentId: number, page: number, size: number, lang: string): Observable<Page<AlarmInfo>> {
    let params = new HttpParams()
    params = params.append('lang', lang)
    return this.getPaged(equipmentId + '/active', page, size, params)
  }


  getActiveAlarmInfo(equipmentId: number): Observable<ActiveAlarmInfoEntry[]> {
    return this.get(equipmentId + '/active/info')
  }
}
