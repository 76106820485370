
import { Injectable } from '@angular/core'
import {
  AuthService,
  BaseService,
  Equipment,
  EQUIPMENT_ADMIN,
  EQUIPMENT_EXEMPT, EQUIPMENT_MAINTAIN,
  EQUIPMENT_OPERATE,
  EQUIPMENT_WRITE,
  EquipmentEntry,
  EquipmentInfo,
  EquipmentUpdateRequest,
  Page,
  ResourceHierarchyNode
} from '@mybitzer-workspace/shared'
import { Observable } from 'rxjs'
import { Filter } from '../filter'


@Injectable({
  providedIn: 'root'
})
export class EquipmentService extends BaseService {

  constructor(private readonly authService: AuthService) {
    super('equipment')
  }

  getInfo(equipmentId: number): Observable<EquipmentInfo> {
    return this.get(equipmentId + '/info')
  }

  getEquipment(equipmentId: number): Observable<Equipment> {
    return this.get('' + equipmentId)
  }

  getPath(equipmentId: number): Observable<ResourceHierarchyNode[]> {
    return this.get(equipmentId + '/path')
  }

  getSystemEquipments(systemId: number, page: number, size: number): Observable<Page<EquipmentInfo>> {
    return this.getPaged('system/' + systemId, page, size)
  }

  getFilteredSystemEquipments(systemId: number, filter: Filter, page: number, size: number): Observable<Page<EquipmentInfo>> {
    return this.postPaged('system/' + systemId, filter, page, size)
  }

  getSystemEquipmentsAndFilteredProperties(systemId: number, properties: string[], lang: string, page: number, size: number): Observable<Page<EquipmentEntry>> {
    return this.postPaged(`system/${systemId}/properties`, properties, page, size)
  }

  hasEquipmentEditPermissions(): boolean {
    return this.authService.hasRole(EQUIPMENT_WRITE) || this.authService.hasRole(EQUIPMENT_ADMIN)
  }

  hasOperatePermissions(): boolean {
    return this.authService.hasRole(EQUIPMENT_OPERATE)
  }

  hasExemptPermissions(): boolean {
    return this.authService.hasRole(EQUIPMENT_EXEMPT)
  }

  hasMaintenancePermissions(): boolean {
    return this.authService.hasRole(EQUIPMENT_MAINTAIN)
  }

  deleteEquipment(equipmentId: number): Observable<Equipment> {
    return this.delete('' + equipmentId)
  }

  changeEquipment(equipmentId: number, request: EquipmentUpdateRequest): Observable<Equipment> {
    return this.put('' + equipmentId, request)
  }
}
