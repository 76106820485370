import { HttpEvent, HttpEventType } from "@angular/common/http"
import { Component, input, OnDestroy, OnInit, output } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatFormField, MatLabel } from '@angular/material/form-field'
import { MatInput } from '@angular/material/input'
import { HotToastService } from "@ngxpert/hot-toast"
import { Subject, Subscription } from "rxjs"
import { finalize } from "rxjs/operators"


import { MatProgressBar } from '@angular/material/progress-bar'
import { TranslatePipe } from '@ngx-translate/core'
import { StorageDocument } from "../document-api"
import { DocumentStorageService } from "../document-storage.service"

@Component({
  selector: 'lib-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss'],
  imports: [MatFormField, MatLabel, MatInput, ReactiveFormsModule, FormsModule, MatProgressBar, TranslatePipe]
})
export class DocumentUploadComponent implements OnInit, OnDestroy {

  readonly itemId = input<number>()
  readonly submitUpload = input<Subject<any>>()
  readonly event = output<boolean>()
  fileName = ''
  file: File | undefined

  uploadProgress: number | undefined
  uploadSub: Subscription | undefined
  uploading = false
  title = ''

  constructor(private service: DocumentStorageService, private toast: HotToastService) {
  }

  ngOnInit() {
    const submitUpload = this.submitUpload()
    if (!submitUpload) return
    submitUpload.subscribe(e => {
      this.onUpload()
    })
  }

  ngOnDestroy() {
    this.submitUpload()?.unsubscribe()
  }

  onFileSelected(event: any) {
    if (!this.itemId()) return

    const file: File = event.target.files[0]
    if (!file) return

    this.file = file
    this.fileName = file.name
    this.uploading = true
  }

  onUpload() {
    const formData = new FormData()
    const itemId = this.itemId()
    if (!this.file || !itemId) return
    formData.append("file", this.file, this.fileName)

    const upload$ = this.service.upload(itemId, formData).pipe(
      finalize(() => this.reset())
    )

    this.uploadSub = upload$.subscribe(
      {
        next: (evt) => this.handleUpdate(evt),
        error: (err) => this.handleFailed(err),
      })
  }

  private handleUpdate(event: HttpEvent<StorageDocument>) {
    if (event.type == HttpEventType.UploadProgress) {
      this.uploadProgress = Math.round(100 * (event.loaded / event.total!))
    } else if (event.type == HttpEventType.Response) {
      this.handleCompleted(event.body!)
    }
  }

  private handleCompleted(v: StorageDocument) {
    const itemId = this.itemId()
    if (!itemId) return
    const request = {
      value: this.title
    }
    this.service.setTitle(itemId, v.id, request).subscribe(it => {
      this.toast.success("Successfully uploaded file '" + this.fileName + "' ")
      this.event.emit(true)
    })
  }

  private handleFailed(e: any) {
    this.toast.error("Failed to upload file '" + this.fileName + "' ")
    this.event.emit(false)
  }

  reset() {
    this.uploadProgress = undefined
    this.uploadSub = undefined
    this.uploading = false
  }
}
