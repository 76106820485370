import { Page } from '@mybitzer-workspace/shared'
import { ChangeLog } from "../material"

export interface ProductConfigSelectResult {
  request: ProductConfigSelectRequest
  items: ConfigItemEntry[]
  entries: ProductConfigIdInfo[]
  suggestedEntries: ProductConfigIdInfo[]
}

export interface ConfigItemEntry {
  key: string
  defaultName: string
  name: string
  selectionType: ConfigSelectionType
  selectedValue: CsticValueInfo | null
  defaultValue: CsticValueInfo | null
  possibleValues: CsticValueInfo[]
}

export interface ProductConfigIdInfo {
  id: string,
  title: string,
  status: string,
  changeLog: ChangeLog,
  internal: boolean,

  matNrKMat: string,
  type: string,
  plant: string,
  standard: boolean,
  suggested: boolean,
  groups: CsticsGroupInfo[],
}

export interface CsticsGroupInfo {
  id: string,
  defaultName: string,
  name: string,
  rank: number,
  cstics: CsticInfo[]
}

export interface CsticInfo {
  key: string
  defaultName: string
  name: string
  value: CsticValueInfo,
  rank: number
}

export interface CsticValueInfo {
  key: string
  defaultName: string
  name: string
}

export class ProductConfigSelectRequest {
  constructor(
    public productId: string,
    public entries: ConfigItemSelectEntry[]
  ) {
  }
}


export class ConfigItemSelectEntry {
  constructor(
    public key: string,
    public value: string,
    public selectionType: ConfigSelectionType
  ) {
  }
}

export enum ConfigSelectionType {
  USER = 'USER',
  STANDARD = 'STANDARD',
  ONLY_REMAINING_OPTION = 'ONLY_REMAINING_OPTION',
  NO_SELECTION = 'NO_SELECTION'
}


export class ProductComparisonRequest {
  constructor(
    public configIds: string[]
  ) {
  }
}

export interface ProductComparisonResponse {
  header: ConfigCompareHeader[]
  groups: ConfigCompareGroup[]
}

export interface ConfigCompareGroup {
  id: string
  defaultName: string
  name: string
  similarValues: boolean
  rows: ConfigCompareRow[]
}

export interface ConfigCompareRow {
  defaultName: string
  key: string
  name: string
  similarValues: boolean
  values: ConfigItemValueEntry[]
}

export interface ConfigCompareHeader {
  id: string,
  matNrKMat: string,
  type: string
}

export interface ConfigItemValueEntry {
  key: string
  defaultName: string
  name: string
}

export interface ProductConfigIdAttributes {
  general: ConfigGeneralAttributes,
  selection: SelectionAttributes
}

export interface ConfigGeneralAttributes {
  matNrKMat: string,
  type: string,
  plant: string,
}

export interface SelectionAttributes {
  standard: boolean,
  suggested: boolean,
}

export interface ProductConfigIdReferences {
  cstics: CsticsReferences,
  varConds: VarCondReferences | null
}

export interface CsticsReferences {
  entries: Map<string, Cstic>
}

export interface Cstic {
  key: string,
  defaultName: string,
  name: Map<string, string>,
  ue: CsticValue,
  rank: number
}

export interface CsticValue {
  key: string,
  defaultName: string,
  name: Map<string, string>,
}

export interface VarCondReferences {
  entries: Map<string, VarCond>
}

export interface VarCond {
  key: string,
  currency: string,
  price: number,
}


export class ProductConfigSearchRequest {
  constructor(
    public matNrKMats: string[],
    public status: string[],
    public type: string[],
    public standard: boolean,
    public suggested: boolean,
    public fullTextSearch: string,
    public lang: string
  ) {
  }
}


export interface ProductConfigSearchResponse {
  entries: Page<ProductConfigSearchResultEntry>
  matNrKMats: ProductConfigFilterResultEntry[],
  status: ProductConfigFilterResultEntry[],
  type: ProductConfigFilterResultEntry[],
}

export interface ProductConfigSearchResultEntry {
  id: string,
  matNrKMat: string,
  name: string,
  type: string,
  plant: string,
  status: string,
  internal: boolean,
  standard: boolean,
  suggested: boolean
}

export interface ProductConfigFilterResultEntry {
  value: string,
  amount: number
}
