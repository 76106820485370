import { Injectable } from '@angular/core'
import { BaseService } from "@mybitzer-workspace/shared"
import { Observable } from "rxjs"
import { BestInfo, DeviceUpdateRequest, DeviceUpdateResponse } from "./best-core-api"

@Injectable({
  providedIn: 'root'
})
export class BestCoreGatewayService extends BaseService {

  constructor(
  ) {
    super('best')
    this.retryCount = 1
  }
  getInfo(equipmentId: number, thingId: number): Observable<BestInfo> {
    return this.get('info/' + equipmentId + '/thing/' + thingId)
  }

  clearAlarms(equipmentId: number, thingId: number) {
    return this.put('clear/alarms/' + equipmentId + '/thing/' + thingId, {})
  }

  update(equipmentId: number, request: DeviceUpdateRequest): Observable<DeviceUpdateResponse> {
    return this.put('device/' + equipmentId + '/update', request)
  }
}
