import { KnowledgeEquipment } from "../knowledge/equipment/equipment-api"

export interface ExportDeviceEntry {
  compressor: KnowledgeEquipment
}

export class ExportJobChangeRequest {
  constructor(
    public name: string,
    public description: string
  ) {
  }
}

export interface ExportJob {
  id: number,
  name: string,
  description: string,
  creator: string,
  status: string
}

export interface ExportJobTask {
  id: number,
  thingId: number,
  start: string,
  stop: string,
  status: string
}


export class ExportJobTaskChangeRequest {
  constructor(
    public thingId: string,
    public start: string,
    public stop: string
  ) {
  }
}
