import { CdkScrollable } from '@angular/cdk/scrolling'
import { Component, Inject } from '@angular/core'
import { MatButton } from '@angular/material/button'
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from "@angular/material/dialog"
import { TranslatePipe } from '@ngx-translate/core'
import { Subject } from "rxjs"
import { DocumentUploadComponent } from '../document-upload/document-upload.component'

@Component({
  selector: 'lib-document-upload-dialog',
  templateUrl: './document-upload-dialog.component.html',
  styleUrls: ['./document-upload-dialog.component.scss'],
  imports: [MatDialogTitle, CdkScrollable, MatDialogContent, DocumentUploadComponent, MatDialogActions, MatButton, MatDialogClose, TranslatePipe]
})
export class DocumentUploadDialogComponent {

  submitUpload: Subject<void> = new Subject()

  constructor(
    @Inject(MAT_DIALOG_DATA) public itemId: number,
    public dialogRef: MatDialogRef<DocumentUploadDialogComponent>
  ) {
  }

  handleUploadFinished() {
    this.dialogRef.close()
  }

  onUpload() {
    this.submitUpload.next()
  }
}
