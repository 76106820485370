import { MaterialType } from "../sales-order"

export interface MaterialInfo {
  id: string,
  type: MaterialType,
  name: string
}

interface NavigationOption {
  path: string
  withQueryParams: boolean
}

export const MaterialTypeUrlMap: Partial<Record<MaterialType, NavigationOption>> = {
  [MaterialType.SPARE_PART]: { path: '/portal/spareparts/details', withQueryParams: false },
  [MaterialType.PRODUCT_ID]: { path: '/portal/pid', withQueryParams: false },
  [MaterialType.PRODUCT]: { path: '/portal/product/details/information', withQueryParams: true }
}

export const ContentPropertyMap: Partial<Record<MaterialType, string[]>> = {
  PRODUCT: ['title', 'series', 'connectorSuction', 'connectorDischarge', '1450hz', '1750hz', 'weight', 'cylinderCount'],
  PRODUCT_ID: ['id', 'type', 'status'],
  SPARE_PART: [
    'id',
    'description',
    'usage',
    'information',
    'material',
    'weight',
    'dimensions'
  ]
}
