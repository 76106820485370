import { Injectable } from '@angular/core'
import { BaseService } from "@mybitzer-workspace/shared"
import { Observable } from "rxjs"
import { ThingTemplate, ThingTemplateInfo } from "./thing-template.api"

@Injectable({
  providedIn: 'root'
})
export class ThingTemplateService extends BaseService {

  constructor() {
    super('template')
    this.retryCount = 0
  }

  getEquipmentTemplate(equipmentId: number): Observable<ThingTemplate> {
    return this.get(equipmentId + '')
  }

  getEquipmentTemplates(equipmentIds: number[]): Observable<ThingTemplate[]> {
    return this.put('', equipmentIds)
  }

  getEquipmentTemplateInfo(equipmentId: number): Observable<ThingTemplateInfo> {
    return this.get(equipmentId + '/info')
  }

  getEquipmentTemplateInfos(equipmentIds: number[]): Observable<ThingTemplateInfo[]> {
    return this.put('info', equipmentIds)
  }
}
