import { Page } from '@mybitzer-workspace/shared'
import { CategorySelectRequest, RelatedCategoryIdEntry } from "../document/document-api"

export interface SearchResultEntry {
  resourceId: number,
  externalId: string,
  name: string,
  type: string,
}

export class ResourceSearchRequest {
  constructor(
    public query: string,
    public onlineFilter: boolean,
    public alarmFilter: boolean,
    public lastAlarms: number,
    public refrigerantFilter: string,
    public systemTypeFilter: string,
    public companyOwnerName: string,
    public typeFilter: string[],
  ) {
  }
}

export interface GeneralSearchResponse {
  documents: Page<DocumentSearchResultEntry>
  products: Page<ProductSearchResultEntry>
  series: Page<SeriesSearchResultEntry>
  spareParts: Page<SparePartSearchResultEntry>
}

export class GeneralSearchRequest {
  constructor(
    public fullTextSearch: string,
    public lang: string
  ) {
  }

}

export interface DocumentSearchResponse {
  entries: Page<DocumentSearchResultEntry>
  types: DocumentTypeResultEntry[]
  categories: RelatedCategoryIdEntry[]
}

export interface DocumentTypeResultEntry {
  amount: number,
  type: string,
  typeName: string
}

export interface DocumentSearchResultEntry {
  title?: string,
  lang: string[],
  code: string,
  version: string,
  url: string,
  typeName: string,
  type: string,
  fileType: string,
  hasVideo: boolean,
  technology: string,
}

export class ProductSearchRequest implements CategorySelectRequest {
  selectedApplicationId = ''
  selectedTechnologyId = ''

  constructor(
    public categoryIds: string[],
    public technologyIds: string[],
    public seriesIds: string[],
    public fullTextSearch: string,
    public lang: string
  ) {
  }
}

export interface ProductSearchResponse {
  entries: Page<ProductSearchResultEntry>,
  technology: ProductTechnologyResultEntry[],
  series: ProductSeriesResultEntry[],
  categories: RelatedCategoryIdEntry[]
}

export interface ProductSearchResultEntry {
  id: string,
  technology: string,
  technologyName: string,
  series: string,
  seriesName: string,
  legacy: boolean,
  internal: boolean,
  matNrKMat: string,
  hasDocuments: boolean,
  hasSpareParts: boolean,
  hasProductConfigIds: boolean
}

export interface ProductTechnologyResultEntry {
  technology: string,
  amount: number
}

export interface ProductSeriesResultEntry {
  series: string,
  seriesName: string,
  amount: number
}

export class SeriesSearchRequest implements CategorySelectRequest {
  selectedApplicationId = ''
  selectedTechnologyId = ''

  constructor(
    public categoryIds: string[],
    public fullTextSearch: string,
    public lang: string
  ) {
  }
}

export interface SeriesSearchResponse {
  entries: Page<SeriesSearchResultEntry>,
  categories: RelatedCategoryIdEntry[]
}

export interface SeriesSearchResultEntry {
  id: string,
  title: string,
  description: string,

  picture: string,
  mediaTitle: string,
  pictogram: string[],

  documents: string[]
}

export class SparePartSearchRequest {
  constructor(
    public fullTextSearch: string,
    public lang: string
  ) {
  }
}

export interface SparePartSearchResponse {
  entries: Page<SparePartSearchResultEntry>
}

export interface SparePartSearchResultEntry {
  id: string,
  titleSales: string,
  titleShort: string,
  crossplantStatus: string,
  weight: string,
  dimensions: string,
  picture: string,
  internal: boolean,
  description: string
}
