import { HttpParams } from "@angular/common/http"
import { Injectable } from '@angular/core'
import { BaseService, Page } from '@mybitzer-workspace/shared'
import { Observable } from "rxjs"
import { EventSearchRequest, StatusEvent } from "./event.api"

@Injectable({
  providedIn: 'root'
})
export class EventService extends BaseService {

  constructor() {
    super('event')
    this.retryCount = 0
  }

  findEvents(equipmentId: number, request: EventSearchRequest, page: number, size: number): Observable<Page<StatusEvent>> {
    return this.putPaged(equipmentId + '/find', request, page, size)
  }

  findLastBefore(equipmentId: number, categoryId: number, timestamp: string): Observable<StatusEvent> {
    let params = new HttpParams()
    params = params.append('timestamp', timestamp)
    return this.get(`/${equipmentId}/find/category/${categoryId}/last/before`, params)
  }
}
