import { HttpEvent, HttpResponse } from "@angular/common/http"
import { Injectable } from '@angular/core'
import { AuthService, BaseService, DOCUMENT_WRITE, Page } from "@mybitzer-workspace/shared"
import { Observable } from "rxjs"
import { DocumentTitleRequest, StorageDocument } from "./document-api"

@Injectable({
  providedIn: 'root'
})
export class DocumentStorageService extends BaseService {


  constructor(
    private authService: AuthService
  ) {
    super('document/storage')
    this.retryCount = 1
  }

  setTitle(itemId: number, id: number, request: DocumentTitleRequest): Observable<HttpResponse<StorageDocument>> {
    return this.patch(itemId + '/title/' + id, request)
  }

  upload(itemId: number, file: FormData): Observable<HttpEvent<StorageDocument>> {
    const url = this.createUrl('by/item/' + itemId)
    return this.http.post<StorageDocument>(url, file, {
      reportProgress: true,
      observe: 'events'
    })
  }

  downloadDocument(itemId: number, id: number) {
    const path = itemId + '/download/' + id
    return this.download(path)
  }

  findByItemId(itemId: number, page: number, size: number): Observable<Page<StorageDocument>> {
    return this.getPaged('by/item/' + itemId, page, size)
  }

  deleteDocument(id: number, itemId: number) {
    return this.delete(id + '/system/' + itemId)
  }

  hasEditPermissions(): boolean {
    return this.authService.hasRole(DOCUMENT_WRITE)
  }

}
