import { Injectable } from '@angular/core'
import { Observable, Subject } from "rxjs"
import { ComponentIndex } from "./component-index"
/** Deprecated */
@Injectable({
  providedIn: 'root'
})
export class RegistrationSwitchService {

  editTitle: Map<ComponentIndex, string> = new Map([
    [ComponentIndex.Location, 'action.EditLocation'],
    [ComponentIndex.System, 'action.EditSystem'],
    [ComponentIndex.Compressor, 'action.EditCompressor']
  ])

  private componentIndex: ComponentIndex = ComponentIndex.Board
  private activeSiteId = ""
  private reload = new Subject<any>()
  private isInitial = true

  get editMode(): boolean {
    return this.editable
  }

  private editable = false

  get siteId(): string {
    return this.activeSiteId
  }

  private activeSystemId = ""

  get systemId(): string {
    return this.activeSystemId
  }

  private activeEquipmentId = ""

  get equipmentId(): string {
    return this.activeEquipmentId
  }

  private setComponent(index: ComponentIndex) {
    this.componentIndex = index
  }


  get componentId(): ComponentIndex {
    return this.componentIndex
  }

  switchToBoard() {
    this.setComponent(ComponentIndex.Board)
    this.triggerEvent("")
  }

  switchToCreateLocation() {
    this.editable = false
    this.setComponent(ComponentIndex.Location)
  }

  switchToUpdateSite(siteId: string) {
    this.activeSiteId = siteId
    this.editable = true
    this.setComponent(ComponentIndex.Location)
  }

  switchToCreateSystem(siteId: string) {
    this.activeSiteId = siteId
    this.editable = false
    this.setComponent(ComponentIndex.System)
  }

  switchToUpdateSystem(siteId: string, systemId: string) {
    this.activeSiteId = siteId
    this.activeSystemId = systemId
    this.editable = true
    this.setComponent(ComponentIndex.System)
  }

  switchToCreateEquipment(systemId: string) {
    this.activeSystemId = systemId
    this.editable = false
    this.setComponent(ComponentIndex.Compressor)
  }

  switchToUpdateEquipment(siteId: string, systemId: string, equipmentId: string) {
    this.activeSiteId = siteId
    this.activeSystemId = systemId
    this.activeEquipmentId = equipmentId
    this.editable = true
    this.setComponent(ComponentIndex.Compressor)
  }

  private triggerEvent(event: string) {
    this.reload.next(event)
  }

  get events$(): Observable<any> {
    return this.reload.asObservable()
  }

  initiate() {
    if (this.isInitial) {
      this.isInitial = false
      this.switchToCreateLocation()
    }
  }

  cancel() {
    this.isInitial = true
  }


}
