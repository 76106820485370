import { Injectable } from '@angular/core'
import { BaseService } from "@mybitzer-workspace/shared"
import { catchError, Observable, of } from "rxjs"
import { ActivitySearchRequest, ActivitySearchResponse, SubscriberActivity } from "./activity-api"
import { HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ActivityService extends BaseService {

  constructor() {
    super('activity')
    this.retryCount = 0
  }

  getUnread(pageIndex = 0, pageSize = 10) {
    return this.getPaged<SubscriberActivity>('unread', pageIndex, pageSize)
  }

  getUnreadCount(): Observable<number> {
    return this.get<number>('unread/count').pipe(
      catchError(() => of(0))
    )
  }

  markAsRead(activityId: string) {
    return this.put(`read/${ activityId }`, null)
  }

  getAllActivities(pageIndex = 0, pageSize = 20) {
    return this.getPaged<SubscriberActivity>('', pageIndex, pageSize)
  }

  markAllActivityRead() {
    return this.put('read', {})
  }

  search(request: ActivitySearchRequest, page: number, size: number): Observable<ActivitySearchResponse> {
    const params = new HttpParams()
      .append('page', page)
      .append('size', size)
    return this.post('search', request, params)
  }

}
