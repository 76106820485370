import { Injectable } from '@angular/core'
import { AuthService, BaseService, NOTE_WRITE } from "@mybitzer-workspace/shared"
import { Observable } from "rxjs"
import { Note, NoteChangeRequest } from "./note-api"

@Injectable({
  providedIn: 'root'
})
export class NoteService extends BaseService {

  constructor(
    private readonly authService: AuthService
  ) {
    super('note')
    this.retryCount = 1
  }

  create(itemId: number, request: NoteChangeRequest) {
    return this.post('' + itemId, request)
  }

  findByItemId(itemId: number): Observable<Note> {
    return this.get('by/item/' + itemId)
  }

  update(itemId: number, id: number, request: NoteChangeRequest) {
    return this.put('system/' + itemId + '/id/' + id, request)
  }

  deleteNote(itemId: number, id: number) {
    return this.delete('system/' + itemId + '/id/' + id)
  }

  hasEditPermissions(): boolean {
    return this.authService.hasRole(NOTE_WRITE)
  }

}
