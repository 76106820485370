import {Page} from "@mybitzer-workspace/shared";
import {RelatedCategoryIdEntry} from "../document";

export interface Activity {
  id: string;
  header: ActivityHeader;
  state: ActivityState;
  content: ActivityContent;
  reference: ActivityReference;
}

export interface SubscriberActivity {
  activity: Activity;
  read: boolean;
  timestamp: string;
}

export interface ActivityHeader {
  actor: ActivityActor;
  source: ActivitySource;
  type: ActivityType;
}

export interface ActivityState {
  created: string;
  modified?: string | null;
  expires?: string | null;
  active: boolean;
}

export interface ActivityContent {
  title: string;
  description: string;
}

export interface ActivityReference {
  type: string;
  referenceId: string;
}

export interface ActivityActor {
  id: string;
  key: string
  description: string;
  reference: string;
}

export interface ActivitySource {
  id: number;
  key: string;
  description: string;
}

export interface ActivityType {
  id: number;
  key: string;
  description: string;
}

export class ActivitySearchRequest {
  constructor(
    public fullTextSearch: string,
    public actors: string[] = [],
    public sources: string[] = [],
    public types: string[] = [],
    public from: string|null = null,
    public to: string|null = null,
    public read: boolean|null = null
  ) {}
}

export interface ActivitySearchResponse {
  entries: Page<ActivitySearchResultEntry>,
  actors: AggregationResultEntry[],
  sources: AggregationResultEntry[],
  types: AggregationResultEntry[]
}

export interface AggregationResultEntry {
  name: string,
  amount: number
}

export interface ActivitySearchResultEntry {
  id: string,
  actor: string,
  source: string,
  type: string,
  created: string,
  modified?: string,
  active: boolean,
  title: string,
  description: string,
  referenceType: string,
  referenceId: string,
  subscriberId: number,
  read: boolean
}
