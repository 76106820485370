import { Location } from "@angular/common"
import { Injectable } from '@angular/core'


import {
  AuthService,
  BaseService,
  Company,
  Page,
  ResourceHierarchyNode,
  System,
  SYSTEM_ADMIN,
  SYSTEM_OPERATION,
  SYSTEM_WRITE,
  SystemDetails,
  SystemInfo,
  SystemUpdateRequest
} from "@mybitzer-workspace/shared"
import { Observable } from "rxjs"
import { Filter } from "../filter"
import { ItemPowerEntry, ReportService } from "../report"

@Injectable({
  providedIn: 'root'
})
export class SystemService extends BaseService {
  constructor(private location: Location, private authService: AuthService, private reportService: ReportService) {
    super('system')
  }

  getSystems(siteId: number, page: number, size: number): Observable<Page<SystemInfo>> {
    return this.getPaged('site/' + siteId, page, size)
  }

  getFilteredSystems(siteId: number, filter: Filter, page: number, size: number): Observable<Page<SystemInfo>> {
    return this.postPaged('site/' + siteId + '/filter', filter, page, size)
  }

  getSystem(systemId: number): Observable<System> {
    return this.get<System>('' + systemId)
  }

  getOwner(systemId: number): Observable<Company> {
    return this.get('' + systemId + "/owner")
  }

  getPath(systemId: number): Observable<ResourceHierarchyNode> {
    return this.get(systemId + '/path')
  }

  getInfo(systemId: number): Observable<SystemInfo> {
    return this.get(systemId + '/info')
  }

  getInfos(systemIds: number[]): Observable<SystemInfo[]> {
    return this.post('/info', systemIds)
  }

  getTotalEnergy(siteIds: number[]): Observable<ItemPowerEntry[]> {
    return this.reportService.getSitePower(siteIds)
  }

  getSystemImage(type: string) {
    if (!type) return this.location.prepareExternalUrl('assets/img/picto_system__ref.png')
    switch (type) {
      case 'HP':
        return this.location.prepareExternalUrl('assets/img/picto_system_hp.png')
      case 'AC':
        return this.location.prepareExternalUrl('assets/img/picto_system_ac.png')
      default:
        return this.location.prepareExternalUrl('assets/img/picto_system__ref.png')
    }
  }

  hasSystemEditPermissions(): boolean {
    return this.authService.hasRole(SYSTEM_WRITE) || this.authService.hasRole(SYSTEM_ADMIN)
  }

  hasOperationPermissions(): boolean {
    return this.authService.hasRole(SYSTEM_OPERATION)
  }

  update(systemId: number, request: SystemUpdateRequest): Observable<System> {
    return this.put('' + systemId, request)
  }

  deleteSystem(systemId: number): Observable<System> {
    return this.delete('' + systemId)
  }

  getSystemDetails(id: number, lang: string, page: number, size: number): Observable<SystemDetails> {
    return this.get(id + '/details')
  }

  getFilteredSystemDetails(id: number, request: Filter, lang: string, page: number, size: number): Observable<SystemDetails> {
    return this.post(id + '/details', request)
  }
}
