import { Injectable } from '@angular/core'
import { BaseService } from "@mybitzer-workspace/shared"
import { Observable } from 'rxjs'

export const PERMISSION_EXPORT_ADMIN = "export.admin"
export const PERMISSION_EXPORT_USER = "export.user"

@Injectable({
  providedIn: 'root'
})
export class ExportService extends BaseService {

  constructor(
  ) {
    super('portal/export')
    this.retryCount = 0
  }

  exportProductExcel(): Observable<any> {
    return this.download('product')
  }

  exportPidExcel(): Observable<any> {
    return this.download('pid')
  }

  exportSinglePidExcel(id: string): Observable<any> {
    return this.download('pid/' + id)
  }

  exportPidProductsExcel(): Observable<Blob> {
    return this.download('pid/products')
  }

  exportSparePartExcel(): Observable<any> {
    return this.download('sparepart')
  }
}
