import { HttpResponse } from "@angular/common/http"
import { Injectable } from '@angular/core'
import { BaseService } from "@mybitzer-workspace/shared"
import { Observable } from "rxjs"
import { ProfileDeleteRequest } from "./profile-api"

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends BaseService {

  constructor(
  ) {
    super('profile')
  }

  requestDelete(request: ProfileDeleteRequest): Observable<HttpResponse<any>> {
    return this.postResponse('delete', request)
  }
}
