import { HttpParams, HttpResponse } from "@angular/common/http"
import { Injectable } from '@angular/core'
import { BaseService, Page } from "@mybitzer-workspace/shared"
import { Observable } from "rxjs"
import { ProductSearchRequest, ProductSearchResponse } from "../search/search-api"
import { Product, ProductInfo, SparePartParentCategory } from "./product-api"

@Injectable({
  providedIn: 'root'
})
export class ProductService extends BaseService {

  constructor(
  ) {
    super('product')
    this.retryCount = 1
  }

  getProduct(productId: string): Observable<Product> {
    let params = new HttpParams()
    params = params.append('productId', productId)
    return this.get('', params)
  }

  getProductForEquipment(productId: string, equipmentId: number): Observable<Product> {
    let params = new HttpParams()
    params = params.append('productId', productId)
    return this.get('id/' + equipmentId, params)
  }

  getTypeBySerialNumber(serialNumber: string): Observable<any> {
    return this.get(serialNumber + '/type')
  }

  getCompositeAttributes(productId: string): Observable<Map<string, string[]>> {
    let params = new HttpParams()
    params = params.append('productId', productId)
    return this.get('composite', params)
  }

  findBySparePart(sparePartId: string): Observable<ProductInfo[]> {
    return this.get('find/by/sparepart/' + sparePartId)
  }

  findByCategoryIds(categoryIds: number[], page: number, size: number): Observable<Page<Product>> {
    return this.postPaged('find/by/category', categoryIds, page, size)
  }

  getProductSpareParts(productId: string, equipmentId: number): Observable<SparePartParentCategory[]> {
    let params = new HttpParams()
    params = params.append('productId', productId)
    return this.get(`/id/${equipmentId}/sparepart`, params)
  }

  search(request: ProductSearchRequest, page: number, size: number): Observable<HttpResponse<ProductSearchResponse>> {
    const params = new HttpParams()
      .append('page', page)
      .append('size', size)
    return this.postResponse<ProductSearchResponse>('search', request, params)
  }
}
