import { HttpParams, HttpResponse } from "@angular/common/http"
import { Injectable } from '@angular/core'
import { BaseService } from "@mybitzer-workspace/shared"
import { TranslateService } from "@ngx-translate/core"
import { Observable } from "rxjs"
import { DocumentSearchResponse } from '../search'
import { Document, DocumentSearchRequest } from "./document-api"

@Injectable({
  providedIn: 'root'
})
export class DocumentService extends BaseService {

  constructor(
    private translateService: TranslateService,
  ) {
    super('document')
    this.retryCount = 0
  }

  getDocumentsByIds(ids: string[]): Observable<HttpResponse<Document[]>> {
    const params = new HttpParams()
      .append('lang', this.translateService.currentLang)
    return this.postResponse('', ids, params)
  }

  search(request: DocumentSearchRequest, page: number, size: number): Observable<DocumentSearchResponse> {
    const params = new HttpParams()
      .append('page', page)
      .append('size', size)
    return this.post<DocumentSearchResponse>('search', request, params)
  }
}
