import { Injectable } from '@angular/core'
import { EMPTY, Observable } from "rxjs"
import { map, switchMap, tap } from "rxjs/operators"
import {
  CartEntryChangeRequest,
  CartEntryInfoChangeResponse,
  CartInfo,
  CartSummary,
  PriceRequest,
  PriceRequestEntry,
  PriceResponse
} from "./commerce-api"
import { CommerceService } from "./commerce.service"

@Injectable({
  providedIn: 'root'
})
export class CommerceCartService {

  reloading = false
  cart: CartInfo | undefined

  constructor(private service: CommerceService) {
  }

  reload() {
    if (this.reloading) return EMPTY
    this.reloading = true

    return this.service.getCart().pipe(
      tap(d => this.handleData(d)))

  }

  addToCart(code: string, quantity: number): Observable<CartEntryInfoChangeResponse> {
    const request = new CartEntryChangeRequest(code, quantity)
    return this.service.addToCart(request).pipe(
      switchMap((response) => this.reload().pipe(map(() => response)))
    )
  }

  getPrices(priceEntries: PriceRequestEntry[]): Observable<PriceResponse> {
    const request = new PriceRequest(priceEntries)
    return this.service.getPrices(request)
  }

  updateEntry(entryId: number, code: string, quantity: number) {
    const request = new CartEntryChangeRequest(code, quantity)
    return this.service.updateEntry(entryId, request).pipe(
      switchMap(() => this.reload())
    )
  }

  deleteEntry(entryId: number): Observable<CartSummary> {
    return this.service.deleteEntry(entryId).pipe(
      switchMap((response) => this.reload().pipe(map(() => response)))
    )
  }

  deleteAllEntries(): Observable<CartInfo> {
    return this.service.deleteAllEntries().pipe(
      switchMap(() => this.reload())
    )
  }

  exportCart(): Observable<any> {
    return this.service.exportCart()
  }

  exportSharedCart(sharedCartId: string): Observable<any> {
    return this.service.exportSharedCart(sharedCartId)
  }

  private handleData(d: CartInfo) {
    this.cart = d
    this.reloading = false
  }
}
