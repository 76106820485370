import { EventState } from "./state-api"

export interface Site {
  id: number,
  name: string,
  externalId: string,
  ownerId: number
}

export interface SiteInfo {
  site: Site,
  eventState: EventState,
  alarmState: AlarmLevelEntry[]
}

export interface SiteContact {
  id: number,
  name: string,
  phoneNumber: string,
  email: string,
  primary: boolean
}

export interface SiteAddress {
  id: number,
  line1: string,
  line2: string,
  city: string,
  country: string,
  postalCode: string,
  region: string,
  additionalInformation: string
}

export interface SiteAddressChangeRequest {
  line1: string,
  line2: string,
  city: string,
  country: string,
  postalCode: string,
  region: string,
  additionalInformation: string
}

export interface SiteContactChangeRequest {
  name: string,
  phoneNumber: string,
  email: string,
  primary: boolean
}

export interface GeoLocationChangeRequest {
  location: {
    type: string,
    coordinates: number[]
  }
}

export interface GeoPosition {
  id: number,
  location: {
    type: string,
    coordinates: number[]
  }
}

export interface AlarmLevelEntry {
  level: string,
  amount: number
}

export interface PathEntry {
  id: number,
  name: string,
  children: PathEntry[]
}

export enum IotStatus {
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
  STOPPED = 'STOPPED',
  RUNNING = 'RUNNING',
  FAULT = 'FAULT'
}

export enum AlarmType {
  CRITICAL = 'CRITICAL',
  FAULT = 'FAULT',
  WARNING = 'WARNING'
}

export interface SiteMapEntry {
  info: SiteInfo,
  location: GeoPosition | undefined
}
