import { Injectable } from '@angular/core'
import { BaseService } from '@mybitzer-workspace/shared'
import { Observable } from "rxjs"
import { SiteContact, SiteContactChangeRequest } from "./site-api"

@Injectable({
  providedIn: 'root'
})
export class ContactService extends BaseService {

  constructor(

  ) {
    super('contact')
  }

  create(siteId: number, request: SiteContactChangeRequest): Observable<SiteContact> {
    return this.post<SiteContact>('' + siteId, request)
  }

  update(contactId: number, request: SiteContactChangeRequest): Observable<SiteContact> {
    return this.put<SiteContact>('' + contactId, request)
  }
}
