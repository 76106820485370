import { HttpParams } from "@angular/common/http"
import { Injectable } from '@angular/core'
import { BaseService, Page } from "@mybitzer-workspace/shared"
import { Observable } from "rxjs"
import {
  EnvelopePropertyEntry,
  PropertyDouble,
  PropertyDoubleEdges,
  PropertySearchRequest,
  PropertyText
} from "./property.api"

@Injectable({
  providedIn: 'root'
})
export class PropertyService extends BaseService {

  constructor() {
    super('property')
    this.retryCount = 0
  }


  getLastThingDoubleProperties(thingId: number, filter: string[]): Observable<Map<string, PropertyDouble>> {
    return this.put('last/' + thingId + '/filter/numerical', filter)
  }

  getLastDoubleFavorite(equipmentId: number): Observable<Map<string, PropertyDouble>> {
    return this.get('last/' + equipmentId + '/filter/numerical/favorite')
  }

  getLastThingFavoriteOrDefaultProperties(equipmentIds: number[]): Observable<PropertyDouble[]> {
    return this.put('last/filter/numerical/favorite', equipmentIds)
  }

  getLastThingTextProperties(thingId: number, filter: string[]): Observable<Map<string, PropertyText>> {
    return this.put('last/' + thingId + '/filter/text', filter)
  }

  getDoubleProperties(equipmentId: number, request: PropertySearchRequest, page: number, size: number): Observable<Page<PropertyDouble>> {
    return this.putPaged(equipmentId + '/properties/double', request, page, size)
  }

  getEnvelopePropertyEntries(equipmentId: number, siUnit: string): Observable<EnvelopePropertyEntry[]> {
    let params = new HttpParams()
    params = params.append('siUnit', siUnit)
    return this.get(equipmentId + '/envelope/properties', params)
  }

  getDoublePropertyEdges(equipmentId: number, request: PropertySearchRequest): Observable<PropertyDoubleEdges> {
    return this.put(equipmentId + '/properties/double/edge', request)
  }
}
