import { HttpParams } from "@angular/common/http"
import { Injectable } from '@angular/core'
import { BaseService } from "@mybitzer-workspace/shared"
import { Observable } from "rxjs"
import { WorldWideSearchResponse, WorldWideServiceRequest } from './worldwide-api'

@Injectable({
  providedIn: 'root'
})
export class WorldwideService extends BaseService {
  constructor() {
    super('worldwide')
  }

  search(request: WorldWideServiceRequest): Observable<WorldWideSearchResponse> {
    const params = new HttpParams()
      .append('page', 0)
      .append('size', 1000)

    return this.post('search', request, params)
  }
}
