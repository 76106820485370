import { Injectable } from '@angular/core'
import { ActivateElectronicModuleRequest, BaseService, OnboardRequest, Page, Process, ProcessResponse, TransitionRequest } from "@mybitzer-workspace/shared"
import { Observable } from "rxjs"


@Injectable({
  providedIn: 'root'
})
export class ProcessService extends BaseService {

  constructor(
  ) {
    super('process')
  }

  getById(id: number): Observable<Process> {
    return this.get('' + id)
  }

  getForUser(page: number, size: number): Observable<Page<Process>> {
    return this.getPaged('user', page, size)
  }

  getForUserCompany(page: number, size: number): Observable<Page<Process>> {
    return this.getPaged('company', page, size)
  }

  activate(equipmentId: number, request: ActivateElectronicModuleRequest): Observable<ProcessResponse> {
    return this.post('activate/' + equipmentId, request)
  }

  transition(request: TransitionRequest): Observable<ProcessResponse> {
    return this.post('transition', request)
  }

  onboard(request: OnboardRequest): Observable<ProcessResponse> {
    return this.post('onboard', request)
  }
}
