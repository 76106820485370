
export class QuickLinkRequest {
  constructor(
    public lang: string,
    public country: string,
    public toolsArchive: string,
    public software: string,
  ) {
  }
}

export interface QuickLink {
  id: number,
  name: string,
  entries: QuickLinkAction[]
}

export interface QuickLinkAction {
  id: number,
  name: string,
  image: string,
  icon: string,
  url: string,
  linkText: string,
  rank: number,
  roles: string[],
  internal: boolean,
}
