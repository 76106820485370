import { HttpParams } from "@angular/common/http"
import { Injectable } from '@angular/core'
import { BaseService } from "@mybitzer-workspace/shared"
import { Observable } from "rxjs"
import { ParameterView } from "./parameter-api"

@Injectable({
  providedIn: 'root'
})
export class ParameterService extends BaseService {

  constructor(

  ) {
    super('bestcore/parameter')
  }

  getTranslatedParameterViews(thingId: number, lang: string): Observable<ParameterView[]> {
    let params = new HttpParams()
    params = params.append('lang', lang)
    return this.get('thing/' + thingId + '/views', params)
  }
}
