import { Injectable } from '@angular/core'
import { BaseService, Page } from "@mybitzer-workspace/shared"
import { Observable } from "rxjs"
import { ExportJob, ExportJobChangeRequest } from "./export-api"

@Injectable({
  providedIn: 'root'
})
export class ExportJobService extends BaseService {

  constructor() {
    super('export/job')
  }

  getAllJobs(page: number, size: number): Observable<Page<ExportJob>> {
    return this.getPaged<ExportJob>('', page, size)
  }

  getJob(jobId: string): Observable<ExportJob> {
    return this.get<ExportJob>('' + jobId)
  }

  createJob(request: ExportJobChangeRequest): Observable<ExportJob> {
    return this.post<ExportJob>('', request)
  }

  updateJob(jobId: number, request: ExportJobChangeRequest): Observable<ExportJob> {
    return this.put<ExportJob>('' + jobId, request)
  }

  deleteJob(jobId: number): Observable<any> {
    return this.delete('' + jobId)
  }

  startJob(jobId: number): Observable<ExportJob> {
    return this.put(jobId + '/start', {})
  }

  stopJob(jobId: number): Observable<ExportJob> {
    return this.put(jobId + '/stop', {})
  }

  downloadData(jobId: number): Observable<any> {
    const suffix = jobId + '/download'
    return this.download(suffix)
  }
}
