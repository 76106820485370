import { Injectable } from '@angular/core'
import { BaseService, Page } from "@mybitzer-workspace/shared"
import { Observable } from "rxjs"
import { ExportJobTask, ExportJobTaskChangeRequest } from "./export-api"

@Injectable({
  providedIn: 'root'
})
export class ExportJobTaskService extends BaseService {

  constructor() {
    super('export/job')
  }

  getJobTasks(jobId: number, page: number, size: number): Observable<Page<ExportJobTask>> {
    return this.getPaged<ExportJobTask>(jobId + '/task', page, size)
  }

  createJobTask(jobId: number, request: ExportJobTaskChangeRequest): Observable<ExportJobTask> {
    return this.post(jobId + '/task', request)
  }

  updateJobTask(jobId: number, taskId: number, request: ExportJobTaskChangeRequest): Observable<ExportJobTask> {
    return this.put(jobId + '/task/' + taskId, request)
  }

  deleteJobTask(jobId: number, taskId: number): Observable<any> {
    return this.delete(jobId + '/task/' + taskId)
  }

}
