import { Injectable } from '@angular/core'
import { BaseService, Page } from "@mybitzer-workspace/shared"
import { Observable } from "rxjs"
import { WeatherSeriesEntry, WeatherSeriesRequest } from "./weather-api"

@Injectable({
  providedIn: 'root'
})
export class WeatherService extends BaseService {

  constructor() {
    super('weather')
    this.retryCount = 1
  }

  getWeatherSeries(siteId: number, series: string, request: WeatherSeriesRequest, page: number, size: number): Observable<Page<WeatherSeriesEntry>> {
    return this.postPaged(`${siteId}/${series}`, request, page, size)
  }
}
