import { HttpParams } from "@angular/common/http"
import { Injectable, signal } from '@angular/core'
import { BaseService, Page } from "@mybitzer-workspace/shared"
import { TranslateService } from "@ngx-translate/core"
import { Observable } from "rxjs"
import { ExportService } from "../export/export.service"
import { FindResult } from "../sales-order/sales-order-api"
import {
  ProductComparisonRequest,
  ProductComparisonResponse,
  ProductConfigIdInfo,
  ProductConfigSearchRequest,
  ProductConfigSearchResponse,
  ProductConfigSelectRequest,
  ProductConfigSelectResult
} from "./product-config-api"

@Injectable({
  providedIn: 'root'
})
export class ProductConfigService extends BaseService {
  private comparisonIds = signal<string[]>([])
  comparisonPids = this.comparisonIds.asReadonly()

  constructor(
    private translateService: TranslateService,
    private exportService: ExportService
  ) {
    super('pid')
    this.retryCount = 0
  }

  selectStandard(productId: string): Observable<ProductConfigSelectResult> {
    const params = new HttpParams()
      .append('lang', this.translateService.currentLang)
      .append('productId', productId)
    return this.post('select/standard', {}, params)
  }

  select(request: ProductConfigSelectRequest): Observable<ProductConfigSelectResult> {
    const params = new HttpParams()
      .append('lang', this.translateService.currentLang)
    return this.post('select', request, params)
  }

  compare(request: ProductComparisonRequest): Observable<ProductComparisonResponse> {
    const params = new HttpParams()
      .append('lang', this.translateService.currentLang)

    return this.post('compare', request, params)
  }

  getProductConfigIdInfo(id: string): Observable<ProductConfigIdInfo> {
    const params = new HttpParams()
      .append('lang', this.translateService.currentLang)
    return this.get('' + id + '/info', params)
  }

  search(request: ProductConfigSearchRequest, page: number, size: number): Observable<ProductConfigSearchResponse> {
    const params = new HttpParams()
      .append('page', page)
      .append('size', size)
    return this.post<ProductConfigSearchResponse>('search', request, params)
  }

  findMaterial(query: string, page: number, size: number): Observable<Page<FindResult>> {
    let params = new HttpParams()
    params = params.append('query', query)
    params = params.append('lang', this.translateService.currentLang)
    return this.getPaged('find/material', page, size, params)
  }

  exportPidData(id: string) {
    return this.exportService.exportSinglePidExcel(id)
  }

  addToComparison(pid: string | string[]): void {
    const pids = Array.isArray(pid) ? pid : [pid]

    const newPids = pids.filter(value => !this.comparisonPids().includes(value))
    this.comparisonIds.update(ids => [...ids, ...newPids])

  }

  clearComparisonPids(): void {
    this.comparisonIds.set([])
  }

  removeFromComparison(pid: string) {
    const ids = this.comparisonIds().filter(id => id !== pid)
    this.comparisonIds.set(ids)
  }
}
