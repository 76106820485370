import { Injectable } from '@angular/core'

import { AuthService, BaseService, Page, ResourceHierarchyNode, SITE_ADMIN, SITE_WRITE } from '@mybitzer-workspace/shared'
import { Observable } from "rxjs"
import { Filter } from '../filter/filter.api'
import { ItemPowerEntry, ReportService } from '../report'
import { GeoLocationChangeRequest, GeoPosition, Site, SiteAddress, SiteAddressChangeRequest, SiteContact, SiteInfo, SiteMapEntry } from "./site-api"


@Injectable({
  providedIn: 'root'
})
export class SiteService extends BaseService {

  constructor(
    private readonly authService: AuthService,
    private readonly reportService: ReportService
  ) {
    super('site')
  }

  setName(siteId: number, value: string): Observable<Site> {
    return this.put<Site>(siteId + '/name', { value })
  }

  getSites(page: number, size: number): Observable<Page<SiteInfo>> {
    return this.getPaged('', page, size)
  }

  getSiteMapEntries(page: number, size: number): Observable<Page<SiteMapEntry>> {
    return this.getPaged('map', page, size)
  }

  getFilteredSites(filter: Filter, page: number, size: number): Observable<Page<SiteInfo>> {
    return this.postPaged('filter', filter, page, size)
  }

  getFilteredSiteMapEntries(filter: Filter, page: number, size: number): Observable<Page<SiteMapEntry>> {
    return this.postPaged('map/filter', filter, page, size)
  }

  getChildSites(siteId: number, page: number, size: number): Observable<Page<SiteInfo>> {
    return this.getPaged(siteId + '/children', page, size)
  }

  getFilteredChildSites(siteId: number, filter: Filter, page: number, size: number): Observable<Page<SiteInfo>> {
    return this.postPaged(siteId + '/children/filter', filter, page, size)
  }

  getSite(siteId: number): Observable<Site> {
    return this.get('' + siteId)
  }

  getAddress(siteId: number): Observable<SiteAddress> {
    return this.get(siteId + '/address')
  }

  updateAddress(siteId: number, request: SiteAddressChangeRequest): Observable<SiteAddress> {
    return this.put(siteId + '/address', request)
  }

  getContact(siteId: number, page: number, size: number): Observable<Page<SiteContact>> {
    return this.getPaged(siteId + '/contact', page, size)
  }

  getPath(siteId: number): Observable<ResourceHierarchyNode> {
    return this.get(siteId + '/path')
  }

  getGeoPosition(siteId: number): Observable<GeoPosition> {
    return this.get(siteId + '/geo')
  }


  updateGeoPosition(siteId: number, request: GeoLocationChangeRequest): Observable<GeoPosition> {
    return this.put<GeoPosition>(siteId + '/geo', request)
  }


  getInfo(siteId: number): Observable<SiteInfo> {
    return this.get(siteId + '/info')
  }

  getInfos(siteIds: number[]): Observable<SiteInfo[]> {
    return this.post('/info', siteIds)
  }

  getTotalEnergy(siteIds: number[]): Observable<ItemPowerEntry[]> {
    return this.reportService.getSitePower(siteIds)
  }

  deleteSite(siteId: number): Observable<Site> {
    return this.delete('' + siteId)
  }

  hasSiteEditPermissions(): boolean {
    return this.authService.hasRole(SITE_WRITE) || this.authService.hasRole(SITE_ADMIN)
  }
}
