<div class="file-upload flex flex-col gap-2 items-center">

  <mat-form-field class="example-full-width">
    <mat-label>{{'shortDescription' | translate}}</mat-label>
    <textarea matInput [(ngModel)]="title" class="input-area"></textarea>
  </mat-form-field>

  <div class="text-center">
    <input class="form-control" type="file" accept="*/*" (change)="onFileSelected($event)">
  </div>

  @if (uploading) {
    <mat-progress-bar class="progress-bar" mode="determinate" [value]="uploadProgress">
    </mat-progress-bar>
  }
</div>
