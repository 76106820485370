import { Page } from '@mybitzer-workspace/shared'
import { MaterialInfo } from "../material/material.const"

export interface SalesOrder {
  id: string,
  header: SalesOrderHeader,
  items: SalesOrderItem[],
  state: SalesOrderState
}

export interface SalesOrderHeader {
  type: string,
  creator: string,
  lastChanger: string,
  created: string,
  changed: string,

  salesArea: SalesArea,
  salesGroup: string,
  salesOfficeInfo: FindResult,
  b2bUnitInfo: FindResult,
  customerReference: string,

  totalNetAmount: number,
  currency: string,

  requestedDeliveryDate: string,
  customerPurchaseOrderDate: string,
  customerPurchaseOrderType: string,
  customerPaymentTerms: string,
  shippingType: string,
  incotermsClassification: string,
  incotermsTransferLocation: string,
}

export interface SalesOrderHistoryLog {
  entries: SalesOrderHistoryLogEntry[]
}

export interface SalesOrderHistoryLogEntry {
  timestamp: Date
  author: string
  changes: SalesOrderHistoryLogChange[]
}

export interface SalesOrderHistoryLogChange {
  key: string
  former?: any
  current?: any
}


export interface SalesOrderItem {
  itemId: string,
  creator: string,
  created: string,
  baseUnit: string,
  weight: number,
  materialInfo: MaterialInfo,
  netAmount: number,
  netPriceAmount: number,
  quantity: number,
  quantityUnit: string,
  state: SalesOrderItemState,
  dispatch: SalesOrderDispatchState,
  scheduleLine: SalesOrderScheduleLine[]
}

export enum MaterialType {
  SPARE_PART = 'SPARE_PART',
  PRODUCT_CONFIG_ID = 'PRODUCT_CONFIG_ID',
  PRODUCT_ID = 'PRODUCT_ID',
  PRODUCT = 'PRODUCT',
  UNKNOWN = 'UNKNOWN',
  PRODUCT_FAMILY = 'PRODUCT_FAMILY',
}

export interface SalesOrderItemState {
  status: string,
  statusChanged: string | undefined,
  rejectReason: string,
  completed: boolean
}

export interface SalesArea {
  id: number,
  salesOrg: string,
  distChannel: string,
  division: string,
}

export interface EntryDispatchStatus {
  delayed: boolean,
  state: string
}

export interface SalesOrderDispatchState {
  itemId: string,
  state: string,
  entries: SalesOrderItemDispatchStateEntry[]
}

export interface SalesOrderItemDispatchStateEntry {
  timestamp: string,
  status: EntryDispatchStatus,
  quantities: SalesOrderItemConfirmedQuantity[]
}

export interface SalesOrderItemConfirmedQuantity {
  deliveryDate: string,
  changeReason: string,
  confirmedQuantity: number,
  orderQuantityUnit: string
}

export interface SalesOrderScheduleLine {
  scheduleLine: string,
  quantityUnit: string,
  expected: SalesOrderScheduleLineItem,
  confirmed: SalesOrderScheduleLineItem[],
  status: string,
}

export interface SalesOrderScheduleLineItem {
  quantity: number,
  deliveryDate: string,
  changeReason: string
}

export interface SalesOrderState {
  overallStatus: string,
  overallStatusChanged: string | undefined,
  itemsOnTrack: number,
  itemsPostponed: number,
  itemsAdvanced: number,
  itemsDelayed: number,
  completed: boolean
}

export class SalesOrderSearchRequest {
  constructor(
    public fullTextSearch: string,
    public from: string,
    public to: string,
    public confirmedDeliveryFrom: string,
    public confirmedDeliveryTo: string,
    public status: string[],
    public b2bUnit: string[],
    public salesOffice: string[],
    public salesOrg: string,
    public distChannel: string,
    public shippingType: string,
    public onlyItemsChanged: boolean | null = null,
    public onlyItemsDelayed: boolean | null = null,
    public completed: boolean | null = null,
  ) {
  }
}

export const DEFAULT_SEARCH_REQUEST = new SalesOrderSearchRequest('', '', '', '', '', [], [], [], '', '', '', null, null, null)

export interface SalesOrderSearchInfoResponse {
  entries: Page<SalesOrderSearchResultEntryInfo>,
  status: SalesOrderStatusResultEntry[]
}

export interface SalesOrderSearchResultEntryInfo {
  id: string,

  type: string,
  creator: string,
  lastChanger: string,

  created: string,
  changed: string,

  salesArea: SalesArea,
  salesGroup: string,
  salesOfficeInfo: FindResult,
  b2bUnitInfo: FindResult,
  customerReference: string,

  totalNetAmount: number,
  currency: string,

  requestedDeliveryDate: string,
  customerPurchaseOrderDate: string,
  customerPurchaseOrderType: string,
  customerPaymentTerms: string,

  shippingType: string,

  status: string,
  incotermsClassification: string,
  incotermsTransferLocation: string,

  itemsDelayed: number
  itemsOnTrack: number
  itemsPostponed: number
  itemsAdvanced: number
  completed: boolean
}

export interface SalesOrderStatusResultEntry {
  status: string,
  amount: number
}

export interface FindResult {
  id: string,
  name: string
}

export interface SalesHub {
  id: string,
  name: string
}

export class SalesRelationSearchRequest {
  constructor(
    public fullTextSearch: string,
    public salesOfficeIds: string[] = []
  ) {
  }
}

export interface LongResult {
  value: number
}
