import { Injectable } from '@angular/core'
import { BaseService } from "@mybitzer-workspace/shared"
import { Observable } from "rxjs"

import { EquipmentLoadReport, EquipmentLoadReportRequest } from "./operational-report-load.api"
import { EquipmentOperationalReport, EquipmentOperationReportRequest } from "./operational-report.api"
import { ItemPowerEntry, PowerReport, PowerSearchRequest } from './power-report.api'


@Injectable({
  providedIn: 'root'
})
export class ReportService extends BaseService {

  constructor() {
    super('report')
  }

  getEquipmentReport(equipmentId: number, request: EquipmentOperationReportRequest): Observable<EquipmentOperationalReport> {
    return this.post('equipment/' + equipmentId + '/operation', request)
  }

  getEquipmentLoadReport(equipmentId: number, request: EquipmentLoadReportRequest): Observable<EquipmentLoadReport> {
    return this.post('equipment/' + equipmentId + '/load', request)
  }

  getPowerReportSystemDaily(systemId: number, request: PowerSearchRequest): Observable<PowerReport> {
    return this.post('system/' + systemId + '/power/daily', request)
  }

  getPowerReportSystemWeekly(systemId: number, request: PowerSearchRequest): Observable<PowerReport> {
    return this.post('system/' + systemId + '/power/weekly', request)
  }

  getPowerReportSystemMonthly(systemId: number, request: PowerSearchRequest): Observable<PowerReport> {
    return this.post('system/' + systemId + '/power/monthly', request)
  }

  getSitePower(siteIds: number[]): Observable<ItemPowerEntry[]> {
    return this.post('site', siteIds)
  }

  getSystemsPower(systemIds: number[]): Observable<ItemPowerEntry[]> {
    return this.post('system', systemIds)
  }
}
