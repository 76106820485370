import { Injectable } from '@angular/core'
import { BehaviorSubject } from "rxjs"
import { Filter } from './filter.api'

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  activeFilter: BehaviorSubject<Filter> = new BehaviorSubject<Filter>({
    alarmFilter: false,
    lastAlarms: 1,
    onlineFilter: false,
    refrigerationFilter: '',
    typeFilter: '',
    companyOwnerName: ''
  })

  activated = false
  siteFilterActive = false
  filterPanelActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  setAlarmFilter() {
    this.toggleFilter('alarmFilter')
  }

  setOnlineFilter() {
    this.toggleFilter('onlineFilter')
  }

  setLastAlarmsFilter(value: number) {
    this.activeFilter.value.lastAlarms = value
    this.activated = true
    this.activeFilter.next(this.activeFilter.value)
  }

  setSiteFilter(refrig: string, type: string, owner: string) {
    this.activeFilter.value.refrigerationFilter = refrig
    this.activeFilter.value.typeFilter = type
    this.activeFilter.value.companyOwnerName = owner
    this.activated = !this.activated
    if ((refrig + type + owner).length == 0) {
      this.siteFilterActive = false
    } else {
      this.siteFilterActive = true
    }
    this.activeFilter.next(this.activeFilter.value)
  }

  toggleFilterDisplay() {
    this.toggle(this.filterPanelActive)
  }

  private toggleFilter(key: keyof Filter) {
    const filter = !this.activeFilter.value[key]
    this.activated = true
    this.activeFilter.next({
      ...this.activeFilter.value,
      [key]: filter
    })


  }

  private toggle(subject: BehaviorSubject<boolean>) {
    subject.next(!subject.getValue())
  }


}
