export class ProfileDeleteRequest {
  public constructor(
    public name: string,
    public surname: string,
    public company: string,
    public emailDelete: string,
    public emailCommunicate: string,
    public reason: string | null
  ) {
  }
}
