import { Injectable } from '@angular/core'
import { BaseService } from "@mybitzer-workspace/shared"
import { QuickLink, QuickLinkRequest } from "./quick-link-api";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuickLinkService extends BaseService {

  constructor(

  ) {
    super('quicklink')
  }

  find(request: QuickLinkRequest): Observable<QuickLink> {
    return this.post('', request)
  }
}
