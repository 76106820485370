import { HttpParams, HttpResponse } from "@angular/common/http"
import { Injectable } from '@angular/core'
import { BaseService } from "@mybitzer-workspace/shared"
import { TranslateService } from "@ngx-translate/core"
import { Observable } from "rxjs"
import { SeriesSearchRequest, SeriesSearchResponse } from "../search/search-api"
import { SeriesInfo } from "./series-api"

@Injectable({
  providedIn: 'root'
})
export class SeriesService extends BaseService {

  constructor(
    private translateService: TranslateService,
  ) {
    super('series')
    this.retryCount = 0
  }

  getSeries(seriesId: string): Observable<SeriesInfo> {
    const params = new HttpParams()
      .append('lang', this.translateService.currentLang)
    return this.get('' + seriesId, params)
  }

  search(request: SeriesSearchRequest, page: number, size: number): Observable<HttpResponse<SeriesSearchResponse>> {
    const params = new HttpParams()
      .append('page', page)
      .append('size', size)
    return this.postResponse<SeriesSearchResponse>('search', request, params)
  }
}
