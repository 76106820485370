import { Injectable } from '@angular/core'
import { BaseService, Page } from "@mybitzer-workspace/shared"
import { Observable } from "rxjs"
import { ElectronicModule, ElectronicModuleValidationRequest } from "./module.api"

@Injectable({
  providedIn: 'root'
})
export class ModuleService extends BaseService {

  constructor() {
    super('module')
  }

  findByEquipmentId(equipmentId: number, page = 0, size = 10): Observable<Page<ElectronicModule>> {
    return this.getPaged('' + equipmentId, page, size)
  }

  validate(request: ElectronicModuleValidationRequest): Observable<boolean> {
    return this.post('validate', request)
  }
}
