import { HttpParams } from "@angular/common/http"
import { Injectable } from '@angular/core'
import { BaseService, Page } from "@mybitzer-workspace/shared"
import { TranslateService } from "@ngx-translate/core"
import { Observable } from "rxjs"
import { Asset } from "./asset-api"

@Injectable({
  providedIn: 'root'
})
export class AssetService extends BaseService {

  constructor(private translateService: TranslateService,) {
    super('asset')
    this.retryCount = 0
  }

  getAsset(id: string): Observable<Asset> {
    const params = new HttpParams()
      .append('lang', this.translateService.currentLang)
    return this.get('' + id, params)
  }

  getAllAssets(page: number, size: number): Observable<Page<Asset>> {
    const params = new HttpParams()
      .append('lang', this.translateService.currentLang)
    return this.getPaged('', page, size, params)
  }

}
