import { HttpParams } from "@angular/common/http"
import { Injectable } from '@angular/core'
import { BaseService } from "@mybitzer-workspace/shared"
import { TranslateService } from "@ngx-translate/core"
import { Observable } from "rxjs"
import { Category, RootCategories } from "./category-api"

@Injectable({
  providedIn: 'root'
})
export class CategoryService extends BaseService {

  constructor(
    private translateService: TranslateService,
  ) {
    super('category')
    this.retryCount = 1
  }

  getMain(country: string): Observable<Category[]> {
    const params = new HttpParams()
      .append('lang', this.translateService.currentLang)
      .append('country', country)
    return this.get('', params)
  }

  getChildren(categoryId: string, country: string): Observable<Category[]> {
    const params = new HttpParams()
      .append('lang', this.translateService.currentLang)
      .append('country', country)
    return this.get(categoryId + '/children', params)
  }

  getCategory(categoryId: string): Observable<Category> {
    const params = new HttpParams()
      .append('lang', this.translateService.currentLang)
    return this.get('' + categoryId, params)
  }

  getCategories(categoryIds: string[], country: string): Observable<Category[]> {
    const params = new HttpParams()
      .append('lang', this.translateService.currentLang)
      .append('country', country)
    return this.post('', categoryIds, params)
  }

  getPath(categoryId: string, country: string): Observable<Category[]> {
    const params = new HttpParams()
      .append('lang', this.translateService.currentLang)
      .append('country', country)
    return this.get(categoryId + '/path', params)
  }


  getApplicationRoot(): Observable<Category> {
    const params = new HttpParams()
      .append('lang', this.translateService.currentLang)
    return this.get('application', params)
  }

  getTechnologyRoot(): Observable<Category> {
    const params = new HttpParams()
      .append('lang', this.translateService.currentLang)
    return this.get('technology', params)
  }

  getRootCategories(): Observable<RootCategories> {
    const params = new HttpParams()
      .append('lang', this.translateService.currentLang)
    return this.get('root', params)
  }
}
