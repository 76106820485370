import { HttpParams } from "@angular/common/http"
import { Injectable } from '@angular/core'
import { BaseService, Page } from "@mybitzer-workspace/shared"
import { Observable } from "rxjs"
import { EquipmentSearchRequest, KnowledgeEquipment, KnowledgeEquipmentSearchResponse } from "./equipment-api"

@Injectable({
  providedIn: 'root'
})
export class KnowledgeEquipmentService extends BaseService {

  constructor() {
    super('knowledge/equipment')
    this.retryCount = 0
  }


  getEquipment(id: string): Observable<KnowledgeEquipment> {
    return this.get('' + id)
  }

  getAllEquipments(page: number, size: number): Observable<Page<KnowledgeEquipment>> {
    return this.getPaged('', page, size)
  }

  search(request: EquipmentSearchRequest, page: number, size: number): Observable<KnowledgeEquipmentSearchResponse> {
    const params = new HttpParams()
      .append('page', page)
      .append('size', size)
    return this.post<KnowledgeEquipmentSearchResponse>('search', request, params)
  }

}
